import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.scss";

import { PageWrapper } from "./screens/PageWrapper";

import { About } from "./screens/About";
import { Music } from "./screens/Music";
import { Work } from "./screens/Work";
import { Teaching } from "./screens/Teaching";

export const App = () => (
  <Router>
    <Routes>
      <Route
        path="/"
        element={
          <PageWrapper>
            <About />{" "}
          </PageWrapper>
        }
      />

      <Route
        path="/music"
        element={
          <PageWrapper>
            <Music />{" "}
          </PageWrapper>
        }
      />
      <Route
        path="/work"
        element={
          <PageWrapper>
            <Work />{" "}
          </PageWrapper>
        }
      />
      <Route
        path="/teaching"
        element={
          <PageWrapper>
            <Teaching />{" "}
          </PageWrapper>
        }
      />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  </Router>
);
