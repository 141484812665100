import React from "react";

export const PageWrapper = ({ children }) => (
  <>
    {/* <nav>
      <a href="/">About</a>
      <a href="/work">Work</a>
      <a href="/music">Music</a>
      <a href="/teaching">Teaching</a>
    </nav> */}

    <main> {children}</main>
  </>
);
